import { Controller } from "@hotwired/stimulus"
import helpers from '../src/helpers'

export default class extends Controller {
    static values = { selector: String }

    updateValues(_) {
        document.querySelectorAll(this.selectorValue).forEach((elem) => {
            helpers.setNumber(elem, this.element.value)
        })
    }
}
